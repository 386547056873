<!--
 功能名称:千里眼平台(短信)-群发短信
 开发人:梁高权
 创建时间:2021/05/21
 最后修改时间:2021/05/21
-->

<template>
  <div class="groupSMS-wrap">
    <NavBar />
    <div class="content">
      <h1>
        <div>
          短信剩余条数：
          <span>{{note.sms_num}}条</span>
        </div>
        <van-button round size="mini" color="#FFEFC5" @click="callPhone('073182297060')">我要充值短信</van-button>
      </h1>
      <div class="line1">
        <h2>发送群体</h2>
        <div>
          当前已选会员
          <span>{{member_num}}人</span>
        </div>
      </div>
      <van-radio-group v-model="formData.send_type" @change="sendTypeChange" direction="horizontal">
         <van-radio :name="0">发送群体</van-radio>
         <van-radio :name="1">会员卡类型</van-radio>
       </van-radio-group>
      <radioField v-show="formData.send_type===0" v-model="group" :columns="groupList" placement="bottom-end" placeholder="请选择群体"/>
      <gTypes v-show="formData.send_type===1" ref="gTypes" v-model="formData.member_card_type" :list="cardTypeList" />
      <div class="line2">
        <h2>群发内容</h2>
        <van-field v-model="SMScontent.template" rows="3" autosize type="textarea" maxlength="280" placeholder="请输入短信类容" show-word-limit />
        <p>
          1、单条短信长度（即签名、短信内容和退订方式数字之和）不得超过280字。
          <br />
          2、超过70字，按60字/条，逐条计费。单个汉字、标点、英文都 算一个字、回车记两个字。
        </p>
      </div>
      <div class="line3">
        <div class="switch">
          <h2>定时发送</h2>
          <van-switch v-model="checked" size="20px" active-color="#09C89D" inactive-color="#dcdee0" />
        </div>
        <div v-if="checked">
          <van-field
            v-model="Time"
            @click="
              show = !show;
              currentDate = sendingTime;
            "
            readonly
            :right-icon="require('@/assets/images/calendar.png')"
          ></van-field>
          <p>你可以选择任意时刻定时群发，成功设置后不支持修改，但在设定的时间之前可取消，取消后不占用群发条数</p>
        </div>
      </div>
      <van-button round type="info" @click="send">群发短信</van-button>
      <van-popup v-model="show" position="bottom" :style="{ height: '51%' }">
        <van-datetime-picker v-model="currentDate" type="datehour" title="选择年月日小时" :min-date="minDate" @cancel="show = !show" @confirm="confirm" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import table from '@/api/table';
import { mapState } from 'vuex';
import util from '@/utils/util.js';
import NavBar from '@/components/NavBar';
import radioField from '@/components/radioField';
import gTypes from '@/views/index/components/from/gTypes';
export default {
  name: 'groupSMS',
  components: {NavBar, radioField, gTypes },
  computed: {
    ...mapState(['note']),
    Time: function() {
      return util.formatDate(this.sendingTime, 'yyyy-MM-dd hh');
    }
  },
  data() {
    return {
      cardTypeList: [],
      formData:{
        send_type: 0,
        group_name:'',
        sms_content:'你好',
        member_card_state: -1,
        member_card_type: '',
        member_consume_num: [],
        member_consume_time: '',
        member_out_day: 0,
        member_pay_money: [],
        member_pay_money_time: '',
        member_sex: 2
      },
      SMScontent: {}, //短信参数
      groupList:[],//群组list
      group: '', //选中的发送群体
      member_num: 0,//发送会员数量
      checked: false, //控制定时发送
      show: false, //控制时间选择器
      sendingTime: new Date(), //选择的定时发送时间
      minDate: new Date(), //最小可选择时间
      currentDate: new Date() //当前时间选择器绑定的时间
    };
  },
  watch:{
    'group.member_num':function(newVal){
      this.member_num=newVal
    },
    'formData.member_card_type':function(newVal){
      table.memberNum(this.formData).then(response=>{
        this.member_num=response.data.member_num
      })
    }
  },
  created() {
    this.SMScontent = this.note.SMScontent;
    this.memberGroup()
    this.memberCardType()
  },
  methods: {
    // 群发
    send() {
      let {id}=this.group
      if(!this.group&&this.formData.send_type===0){return Toast.fail('请选择发送群体')}
      if (this.formData.send_type===1){
        table.memberGroupAdd(this.formData).then(response=>{
          if(response.data.result){
            this.sendData(response.data.id)
          }
        })
      }else{
        this.sendData(id)
      }
    },
    sendTypeChange(val){
      switch(val){
        case 0:
          this.member_num=this.group.member_num?this.group.member_num:0
          break;
        default:
          table.memberNum(this.formData).then(response=>{
            this.member_num=response.data.member_num
          })
      }
    },
    sendData(id){
      if(this.checked){
        let data={}
        data.group_id=id
        data.sms_content=this.SMScontent.template;
        data.crontab_date = util.formatDate(this.sendingTime, 'yyyy-MM-dd');
        data.crontab_hour = parseInt(util.formatDate(this.sendingTime, 'hh'));
        table.crontabAdd(data).then(response => {
          if (response.data.result) {
            this.$router.push('sendSuccessfully')
          }
        });
      }else{
        let param = {}
        param.group_id=id;
        param.sms_content = this.SMScontent.template;
        table.currSend(param).then(response=>{
          if(response.data.result){
            this.$router.push('sendSuccessfully')
          }
        })
      }
    },
    // 调用手机电话功能
    callPhone(phoneNumber) {
      location.href = 'tel:' + phoneNumber;
    },
    // 选择定时发送时间
    confirm(value) {
      this.show = false;
      this.sendingTime = value;
    },
    // 群组列表
    memberGroup(){
      table.memberGroup({}).then(res=>{
        this.groupList=res.data.list.map(item=>{
           return JSON.parse(JSON.stringify(item).replace(/group_name/g,"text"));
        })
        console.log(this.groupList)
      })
    },
    // 获取会员类型
    memberCardType() {
      table.memberCardType().then(response => {
        // response.data.list.unshift({id: 0, card_type_name: "全部"})
        this.cardTypeList=response.data.list
        this.$refs.gTypes.close(response.data.list)
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.groupSMS-wrap {
  .van-cell::after {
    border-bottom: none;
  }
  /deep/.van-radio-group{
    margin-bottom: 15px;
    .van-radio{
      margin-right: 15px;
    }
  }
  .content {
    min-height: calc(100vh - 112px);
    padding: 0 30px;
    margin-top: 20px;
    background: #fff;
    .van-field {
      line-height: 70px;
      padding: 0 23px;
      background: #ffffff;
      border: 2px solid #e9e9e9;
      border-radius: 10px;
      /deep/.van-icon {
        display: flex;
        align-items: center;
        img {
          width: 33px;
          height: 31px;
        }
      }
    }
    h2 {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
    p {
      margin-top: 27px;
      font-weight: 500;
      font-size: 24px;
      color: #999999;
    }
    h1 {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #e9e9e9;
      > div {
        font-size: 26px;
        font-weight: bold;
        color: #333;
        span {
          color: #ff6600;
        }
      }
      button {
        color: #d05f00 !important;
        font-size: 22px;
        font-weight: 500;
        height: 42px;
        padding: 0 25px;
      }
    }
    .line1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 90px;
      div {
        font-size: 26px;
        font-weight: 500;
        color: #333;
        span {
          color: #2c6bf8;
        }
      }
    }
    .line2 {
      margin-top: 50px;
      .van-field {
        padding-top: 20px;
        font-size: 26px;
        line-height: 32px;
      }
      h2 {
        margin-bottom: 27px;
      }
      /deep/.van-field__word-limit {
        color: #2c6bf8;
      }
    }
    .line3 {
      .switch {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
      }
    }
    > button {
      color: #fff !important;
      background: #2c6bf8;
      font-size: 30px;
      font-weight: 500;
      height: 80px;
      width: 100%;
      margin: 70px 0;
    }
  }
}
</style>
